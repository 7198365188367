
import React from 'react'
import Header from "./components/Header/Header"
import Main from "./components/Main/Main"
import Other from "./components/Main/Other"
import ContactForm from './components/Contact/ContactForm'
import Menu from './components/Menu/Menu'
import Uline from './components/Uline/Uline'

function App() {
  return (
    <div>
      <Header/>
      <Uline/>
      <Uline/>
      <Menu/>
      <Uline/>
      <Uline/>
      <br></br>
      <br></br>      
      <Main/> 
      <br></br>
      <Other/>

    <br>
    </br>
    <br></br>
    
      <ContactForm/>
    </div>
  )
}

export default App
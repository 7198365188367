import React from 'react'
import './Main.css'
import Salesforce from '../../images/Salesforce.png'
import Business from '../../images/Business.jpg'
import Tomclasses from '../../images/Tomclasses.png'
import AWS from '../../images/aws.png'


function Main() {
  return (
  <>
  
      <div className="container">
        <div className="box">
        <img variant='top' src={Salesforce} style={{height:'150px', width:'200px', marginTop:'20px'}} alt='Avator'/>
      <p className='para'>
        With years of Salesforce and technical staffing experience, 
        Tomtechie has been helping clients connect with their target audience,
         track customer activity, and access key insights from the sales pipeline.
        </p>
        <br></br>
        <br></br>
       
        </div>
        <div className="box">
        <img variant="top" src={Business} style={{height:'150px', width:'220px' , marginTop:'20px'}} alt='Avator'/>
        <p className='para'>
        We help our Corporate clients in their Revenue maximisation, Operation Optimisation, Staffing, Technical and 
        Non-Technical Solutions.
        </p>
        </div>
        <div className="box">
        <img variant="top" src={Tomclasses} style={{height:'50px', width:'220px' , marginTop:'20px', borderRadius:'10px'}} alt='Avator'/>
        <p className='para'>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        Tomclasses provides both national and international studdents support in there academic preparation to meet there 
        education objectives. 
        </p>
        </div>
        <div className="box"> <img variant="top" src={AWS} style={{height:'100px', width:'150px' , marginTop:'20px'}} alt='Avator'/>
        <p className='para'>
        <br></br>
          <br></br>
          
        AWS Managed Service Providers (MSP) provide end-to-end AWS solutions to customers at any stage of the 
        cloud journey from consultation on initial solution design, to building applications, through to ongoing 
        
        needs.
        </p>
        </div>

      </div>   
    
  </> 
  )
}

export default Main
import React, {useState} from 'react'
import './Menu.css'

function Menu() {
   
    const [input, setInput]=useState()
  

    const handleChange = (event) => {
     setInput(event.target.value)
    
    }


    const handleClick=(event)=>{
        event.preventDefault()
        const check=Number(input)
        if(check===204){
         window.location.href="https://www.tomtechie.com/varanasi.php"
        }
        else{
            alert("The USERCODE you entered is INCORRECT")
        }
     event.target.reset()
    }
    
  return (
    <div className='Menu'>
      
    <form onSubmit={handleClick}>
     <input type="password"  className="iForm" onChange={handleChange} placeholder="Type your USERCODE....."
      required></input>
     <button type="submit" className='btnA'>SUBMIT</button>
     
    </form>
    </div>
  )
}

export default Menu
import React from 'react'
import './Main.css'

import Tableau from '../../images/Tableau.png'
import PowerBi from '../../images/powerbi.jpg'
import Cyber from '../../images/cybersecurity.png'
import Digital from '../../images/digital.png'

function Other() {
  return (
  <>
  
      <div className="container">
        <div className="box">
          <br></br>   <br></br>
        <img variant='top' src={Tableau} style={{height:'100px', width:'180px', marginTop:'20px'}} alt='Avator'/>
        <br></br>
        <br></br> <br></br>
      <p className='para'>
        Whenever, it comes to automated data visualisation tools, Tableau is the fascinating tool. 
        We help our clients to seamlessly integrate there data sources and generate customised reports
        with the help of Tableau.
        </p>
        
   
        </div>
        <div className="box">
          <br></br><br></br>
        <img variant="top" src={PowerBi} style={{height:'80px', width:'200px' , marginTop:'20px', borderRadius:'10px'}} alt='Avator'/>
        <br></br>  <br></br>  <br></br>  <br></br>
        <p className='para'>
        Power Bi, is another tool at which we master and help organizations to integrate their data sources
        and provide customised analytical reports for better, improved deciion making.
        </p>
        </div>
        <div className="box">
        <img variant="top" src={Cyber} style={{height:'170px', width:'220px' , marginTop:'20px', borderRadius:'10px'}} alt='Avator'/>
        <p className='para'>      
        We deal with various Cyber security issues and challenges faced by the organization. Our machine Learning algorithms 
        help to detect any security issues and threats and suggest remedial actions.
        </p>
        </div>
        <div className="box"> <img variant="top" src={Digital} style={{height:'150px', width:'140px' , marginTop:'20px'}} alt='Avator'/>
        <p className='para'>
        <br></br>
        Digital Forensics, is our new arm where we research for ongoing data breaches and trace the cause of the same.
        </p>
        </div>

      </div>   
    
  </> 
  )
}

export default Other
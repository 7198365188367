import React, { useRef } from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';


const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_720ceai', 'template_4q9ojsn', form.current, 'E1NdQtjeBDd7o-N18')
      .then((result) => {
         
          alert("your message is sent")
      }, (error) => {
         alert(" Your Message was NOT SENT, please contact rav@tomtechie.com")
      });
      e.target.reset()
  };
    return (
      <div className="Contact">
       
        <div className="ContactForm">
          <br></br>
          <br></br>
          <h1 style={{color:"rgb(0, 0, 128)", fontSize:"50px"}}>Contact Us</h1>
           <br></br><br></br>
           <form ref={form} onSubmit={sendEmail}>
     
      <input type="text" name="user_name" className='inputA' placeholder='Your Name Please...' />
      <br></br>
      <br></br>
     
      <input type="email" name="user_email" className='inputB' placeholder='Your Email Please...'/>
      <br></br>
      <br></br>
      
      <input type="number" name="user_phone" className='inputC' placeholder='Your Phone No. Please...' />
      <br></br>
      <br></br>     
      <textarea name="message" className='inputD' placeholder='Your Message Please...'/>
      <br></br>
      <br></br>
      <input type="submit" className='btnB' value="SEND" />
    </form>
        </div>
        </div>
    )
}

export default ContactForm
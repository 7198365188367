import React from 'react'
import "./Header.css"


function Header() {
  return (
    <div className="Header"> 
    <h1 className="title"> Tom techie.com</h1>
    </div>
  )
}

export default Header